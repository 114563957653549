import * as React from "react"
import { Container, Button, Carousel, CarouselItem} from 'react-bootstrap';
import Header from "../components/header"
import FixedFooter from "../components/footer"
import "../styles/index.css"
import PatioImg from "../images/fieldstone-farm-rear-patio-1.jpg"
import WeddingImg from "../images/bride-groom-patio-1.jpg"
import PoolImg from "../images/fieldstone-farm-pool-3.jpg"
import ChairImg from "../images/fieldstone-farm-bedroom-5.jpg"

// styles
const headingStyles = {
    marginTop: 140,
    marginBottom: 100,
}

const buttonStyles = {
  backgroundColor: "#9b3838",
  borderColor: "#9b3838"
}

const imageStyles = {
  maxHeight: "100vh",
}

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Welcome to Fieldstone Farm</title>
      <Header></Header>
      <div id="mycarousel" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="carousel__img" src={PatioImg} alt="Fieldstone Farm's Outdoor Patio"/>
            <div class="carousel__caption carousel-caption">
              <Button href="/the-grounds" className="btn btn-primary" style={buttonStyles}>Tour the grounds</Button>
            </div>
          </div>
          <div class="carousel-item">
            <img class="carousel__img" src={PoolImg} alt="Fieldstone Farm's Heated Pool with Waterfall"/>
            <div class="carousel__caption carousel-caption">
              <Button href="/the-grounds" className="btn btn-primary" style={buttonStyles}>See the amenities</Button>
            </div>
          </div>
          <div class="carousel-item">
            <img class="carousel__img" src={ChairImg} alt="Fieldstone Farm's Colonial Style Rooms"/>
            <div class="carousel__caption carousel-caption">
              <Button href="/the-rooms" className="btn btn-primary" style={buttonStyles}>Tour the rooms</Button>
            </div>
          </div>
          <div class="carousel-item">
            <img class="carousel__img" src={WeddingImg} alt="Fieldstone Farm Hosts Weddings, Anniversaries, and Other Special Events"/>
            <div class="carousel__caption carousel-caption">
              <Button href="/venue" className="btn btn-primary" style={buttonStyles}>Plan your event</Button>
            </div>
          </div>                    
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#mycarousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#mycarousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <FixedFooter/>
    </main>    
  )
}

export default IndexPage
